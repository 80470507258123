import React from 'react'

import { styled } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
`

const Content = styled.div`
  position: relative;
`

const StyledInput = styled.input`
  width: 48px;
  height: 22px;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  margin: 0 !important;
`

const StyledLabel = styled.label<{ isChecked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    transition: background-color ${({ theme }) => theme.transition.hover}ms;
    background-color: ${({ theme, isChecked }) => (isChecked ? theme.palette.dark_pink_red : theme.palette.blue_gray)};
  }

  &::after {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.white};
    top: 2px;
    transition: left ${({ theme }) => theme.transition.hover}ms;
    left: ${({ isChecked }) => (isChecked ? 28 : 2)}px;
  }
`

type CheckboxProps = {
  value: boolean
  onChange: (value: string) => void
}

export const Checkbox: React.FC<CheckboxProps> = ({ value, onChange }) => {
  const handlerCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.checked ? 'true' : '')
  }

  return (
    <Wrapper>
      <Content>
        <StyledInput
          type="checkbox"
          checked={value}
          onChange={(ev) => {
            handlerCheckboxChange(ev)
          }}
        />
        <StyledLabel isChecked={value} />
      </Content>
    </Wrapper>
  )
}
