/* eslint-disable no-useless-escape */
export const regExps = {
  initials: /[a-zA-Zа-яёА-ЯЁ' -]/,
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,15}(\s*)?$/,
  number: /[^0-9.,]/g
}

export const validate = (value: string, regExp: keyof typeof regExps): boolean => regExps[regExp].test(value)
