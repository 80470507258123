import React, { useMemo } from 'react'

import { TProject } from 'app/data/projects'
import { breakpoints } from 'shared/dimensions'
import { Image, RoundBulletLi, RoundButton, Text } from 'ui/components'
import { theme, styled, RoundButtonProps } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  justify-content: center;
  z-index: 10000;
`

const Content = styled.div`
  display: flex;
  overflow: auto;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 63px 32px;
  z-index: 10;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 10;
  margin: auto 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 0 0 42px 0;
  }
`

const StyledTitle = styled(Text)`
  display: flex;
  width: 100%;
  justify-content: center;
`

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
  z-index: 10;
`

const Chip = styled.div`
  display: flex;
  padding: 8px 16px;
  height: 32px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 25px;
  align-items: center;

  :not(:last-child) {
    margin: 0 8px 8px 0;
  }
`

const ImageFilter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #2a3d57 0%, rgba(42, 61, 87, 0) 173.84%), rgba(42, 61, 87, 0.12);
`

const SingleImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
`

const ProjectSummary = styled(Text)`
  margin: 32px 0;
  max-width: 400px;
`

const StyledList = styled.ul`
  max-width: 400px;
`

const StyledListItem = styled(RoundBulletLi)`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.white};

  &::before {
    background-color: ${theme.palette.white};
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

const CloseButton = styled(RoundButton)`
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  z-index: 100;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

type Props = {
  data: TProject
  onClose?(): void
}

export const ProjectSlidePopupMobile: React.FC<Props> = ({ data, onClose }) => {
  const jsxChips = useMemo(
    () =>
      data.chips?.length && (
        <ChipsWrapper>
          {data.chips?.map((chipName) => (
            <Chip key={chipName}>
              <Text variant="t_12_20" color={theme.palette.white}>
                {chipName}
              </Text>
            </Chip>
          ))}
        </ChipsWrapper>
      ),
    [data]
  )

  return (
    <Wrapper>
      <CloseButton onClick={onClose} {...RoundButtonProps} />
      <Content>
        <SingleImage name={data.portfolio} width="100%" height="100%" fit="cover" />
        <ImageFilter />
        <InfoWrapper>
          <DescriptionWrapper>
            <StyledTitle variant="h1" color={theme.palette.white} align="center">
              {data.title?.toUpperCase()}
            </StyledTitle>
            <ProjectSummary variant="t3" color={theme.palette.white}>
              {data.description.summary}
            </ProjectSummary>
            <StyledList>
              {data.description.list?.map((el, i) => (
                <StyledListItem key={i}>{el}</StyledListItem>
              ))}
            </StyledList>
          </DescriptionWrapper>
          {jsxChips}
        </InfoWrapper>
      </Content>
    </Wrapper>
  )
}
