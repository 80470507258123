import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'
import './reset.css'
import './fonts.css'

export const GlobalStyles = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-color: ${theme.palette.blue_gray};
    border: solid transparent;
    border-width: 2px 2px;
    background-clip: content-box;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @supports(scrollbar-width: thin) {
    * {
      scrollbar-width: thin;
      scrollbar-color: ${theme.palette.blue_gray} transparent;
    }
  }

  html {
    overflow: overlay;
  }

  body {
    font-family: ${theme.fontFamily.montserrat}, sans-serif;
    color: ${theme.palette.dark_blue};
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    position: relative;
    height: inherit;
    display: flex;
    transition: filter .3s linear;
  }

  .blur {
    filter: blur(12px);
  }

  .overflow-hidden{
    overflow: hidden;
  }

  b,
  strong {
    font-weight: 600;
  }
`
