import React, { RefObject } from 'react'

import { FieldType, QFieldsT } from 'app/data/questionnaire'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { regExps, validate } from 'shared/lib/validation'
import { useInput } from 'shared/hooks'
import { styled, theme, css } from 'ui/styles'
import { Text, PhoneField, FileInput, RadioGroup, TextArea, Checkbox, PickerInput } from 'ui/components'

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const Label = styled.div<{ minimize: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  min-width: 240px;
  margin-right: 8px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    align-self: start;
  }

  ${({ minimize }) =>
    minimize &&
    css`
      max-width: 240px;
    `}
`

const StyledDescription = styled(Text)`
  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 8px 0;
    max-width: 100%;
  }
`

type Props = {
  field: QFieldsT
}

export const Input: React.FC<Props> = ({ field }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  field.input = useInput({
    initialValue: field.input?.value,
    inputType: field.inputType,
    isRequired: field.isRequired,
    validate: (value) => (field.inputType in regExps ? validate(value, field.inputType as keyof typeof regExps) : true)
  })

  const rows: number = React.useMemo(
    () => (isMobile ? 1 : field.label.length <= 50 ? 1 : 2),
    [field.label.length, isMobile]
  )
  const labelRef: RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null)

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        field.input.handlerFileLoad(event.target.files)
      }
    },
    [field.input]
  )

  const inputLabel: JSX.Element = React.useMemo(
    () => (
      <Label ref={labelRef} minimize={field.minimizeLabel}>
        <Text variant={isMobile ? 't1' : 'h7'} color={field.input.isValid ? 'inherit' : theme.palette.pink_red}>
          {field.label}
        </Text>
        {Boolean(field.labelDescription) && (
          <StyledDescription
            variant="t6"
            color={field.input.isValid ? theme.palette.blue_gray : theme.palette.pink_red}
          >
            {field.labelDescription}
          </StyledDescription>
        )}
      </Label>
    ),
    [field.input.isValid, field.label, field.labelDescription, field.minimizeLabel, isMobile]
  )

  const textareaInput = (
    <TextArea input={field.input} placeholder={field.placeholder} isPdfFile={field.isPdfFile} rows={rows} />
  )
  const phoneInput = <PhoneField {...field.input} />
  const fileInput = (
    <FileInput
      input={field.input}
      handleChange={handleChange}
      isValid={field.input.isValid}
      placeholder={field.placeholder}
      isMultiple={field.isMultiple}
    />
  )

  const radioInput = <RadioGroup {...field} onChange={field.input.onChange} />
  const checkboxInput = <Checkbox onChange={field.input.onChange} value={Boolean(field.input.value)} />
  const pickerInput = (
    <PickerInput onChange={field.input.onChange} input={field.input} customLabels={field.customLabels} />
  )

  const switchInput = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      case FieldType.Phone:
        return phoneInput
      case FieldType.File:
        return fileInput
      case FieldType.Radio:
        return radioInput
      case FieldType.Checkbox:
        return checkboxInput
      case FieldType.TextareaRadio:
        return pickerInput
      default:
        return textareaInput
    }
  }

  return (
    <Wrapper isMobile={isMobile}>
      {inputLabel}
      {switchInput(field.fieldType)}
    </Wrapper>
  )
}
