import React from 'react'

import { styled, theme } from 'ui/styles'
import { Button, Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'

const Wrapper = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 256px;
  padding: 16px 0;
  margin: 0 32px 0 0;

  color: ${theme.palette.white};
  background-color: rgba(255, 255, 255, 0.25);

  backdrop-filter: blur(32px);
  border-radius: 40px;

  transition: background-color, color, ${theme.transition.hover}ms;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    :not(:last-child) {
      margin: 0 0 16px 0;
    }

    :last-child {
      margin: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      color: ${theme.palette.dark_blue};
    }
  }
`

const StyledText = styled(Text)`
  min-width: 100px;
  width: 100%;
`

type AboutMeButtonProps = {
  title: string
  handleClick: () => void
}

export const ResponseButton: React.FC<AboutMeButtonProps> = ({ title, handleClick }) => (
  <Wrapper onClick={handleClick}>
    <StyledText variant="h6" align="center">
      {title}
    </StyledText>
  </Wrapper>
)
