import React from 'react'

import { breakpoints } from 'shared/dimensions'
import { SendRequestReturnT, useSendRequest } from 'shared/hooks'
import { Modal } from 'app/features/modals/templates'
import { QFieldsT, IQuestionnaires } from 'app/data/questionnaire'
import { Separator, Text, Input } from 'ui/components'
import { styled } from 'ui/styles'

import { ContainerLabel, QuestionnaireFooter } from '../molecules'
import { useQuestionnaire } from '../hooks'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const GridWrapper = styled.div<{ isColumns: boolean; isHalfWidth: boolean }>`
  display: flex;
  flex-direction: ${({ isColumns }) => (isColumns ? 'row' : 'column')};
  justify-content: space-between;
  width: 100%;
  width: ${({ isHalfWidth }) => (isHalfWidth ? '50%' : '100%')};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
  }
`

const InputWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header: React.FC<{ title: string }> = ({ title }) => <Text variant="h2">{title}</Text>

type Props = {
  onClose(): void
  onSubmit(): void
  vacancyTitle: string
  questionnaire: IQuestionnaires
}

export const QuestionnaireModal: React.FC<Props> = ({ questionnaire, onSubmit, vacancyTitle, onClose }) => {
  const questData = useQuestionnaire(questionnaire.groups)

  const submitForm = () => {
    const data: Record<string, string | Record<string, string>> = {
      questionnaire: questData.handleSubmit(),
      type: vacancyTitle,
      vacancyType: questionnaire.vacancyType
    }

    if (Object.keys(data).length) {
      request.sendRequest(data)
    }
  }
  const request: SendRequestReturnT = useSendRequest(onSubmit, 'candidateQuestionnaire')

  const formItem = React.useMemo(
    () =>
      questionnaire.groups.map((group) => {
        const groupKey: string = Object.keys(group)[0]

        return group[groupKey].map((section, i) => {
          const isColumnGrid: boolean = section.gridType === 'columns'
          const isHalfWidth: boolean = section.gridType === 'half'

          const fields: QFieldsT[] = section.fields

          return (
            <Wrapper key={`${groupKey}_${i}`}>
              <ContainerLabel label={section.label} placeholder={section.placeholder} boldLabel={section.boldLabel} />
              <GridWrapper isColumns={isColumnGrid} isHalfWidth={isHalfWidth}>
                {isColumnGrid ? (
                  <>
                    <InputWrapper>
                      {fields.map((row, idx) => idx < fields.length / 2 && <Input key={idx} field={row} />)}
                    </InputWrapper>
                    <InputWrapper>
                      {fields.map((row, idx) => idx >= fields.length / 2 && <Input key={idx} field={row} />)}
                    </InputWrapper>
                  </>
                ) : (
                  fields.map((field, idx) => <Input key={idx} field={field} />)
                )}
              </GridWrapper>
              {section.separator && <Separator verticalMargin={12} />}
            </Wrapper>
          )
        })
      }),
    [questionnaire.groups]
  )

  return (
    <Modal header={<Header title={questionnaire.label} />} onClose={() => onClose()} isLoading={request.isLoading}>
      <Form children={formItem} />
      <QuestionnaireFooter handlerSubmit={submitForm} />
    </Modal>
  )
}
