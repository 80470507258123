import { InputReturn, InputType } from 'shared/hooks'

import vacanciesJson from './questionnaire.json'

export type QuestionnaireDataT = {
  questionnaires: IQuestionnaires[]
}

export interface IQuestionnaires {
  label: string
  vacancyType: string
  groups: IGroup[]
}

export type IGroup = {
  [key: string]: IQContainers[]
}

export type IQContainers = {
  label: string
  boldLabel: boolean
  placeholder: string
  gridType: string
  separator: boolean
  fields: QFieldsT[]
  isGridOutput: boolean
}

export enum FieldType {
  Textarea = 'textarea',
  Radio = 'radio',
  Checkbox = 'checkbox',
  File = 'file',
  Quiz = 'quiz',
  Phone = 'phone',
  TextareaRadio = 'textareaRadio'
}

export type QFieldsT = {
  fieldType: FieldType
  inputType: InputType
  input: InputReturn
  label: string
  labelDescription: string
  placeholder: string
  isRequired: boolean
  isAlwaysInRow: boolean
  isAlwaysInColumn: boolean
  isInColumn: boolean
  withFile: boolean
  isTakeAllSpace: boolean
  isWithoutTextArea: boolean // for file
  customLabels: string[]
  isPdfFile: boolean
  isRightPdfOutput: boolean
  minimizeLabel: boolean
  isMultiple: boolean
}

export const QuestionnaireData: QuestionnaireDataT = JSON.parse(JSON.stringify(vacanciesJson))
