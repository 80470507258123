import React from 'react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import HttpService from 'services/main.services'
import { VacancySubmitT } from 'app/features/modals/hooks'

export type SendRequestReturnT = ReturnType<typeof useSendRequest>

export const useSendRequest = (onSubmit: () => void, endpoint: string) => {
  const [queryResult, setQueryResult] = React.useState<string | undefined>(undefined)

  const {
    isLoading,
    mutate: sendRequest,
    isError,
    isSuccess
  } = useMutation<AxiosResponse<any> | undefined, AxiosError, VacancySubmitT>(
    async <T>(data: T) => {
      setQueryResult(undefined)

      return await HttpService.createApplication(data, endpoint)
    },
    {
      onSuccess: (res) => {
        onSubmit()
        setQueryResult(res!.data)
      },
      onError: (err: AxiosError) => {
        setQueryResult(err.message)
      }
    }
  )

  return { queryResult, isLoading, sendRequest, isError, isSuccess }
}
