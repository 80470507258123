import { IGroup } from 'app/data/questionnaire'

export type WorkExperienceReturn = ReturnType<typeof useQuestionnaire>

type TField = {
  title?: string
  value?: string
  isRightPdfOutput?: boolean
}

type TGroup = {
  title: string
  placeholder: string
  separator: boolean
  isGridOutput: boolean
  questions: TField[]
}

export const useQuestionnaire = (questionnaire: IGroup[]) => {
  const handleSubmit = () => {
    let isAllValid = true

    const values = questionnaire.reduce<Record<string, any>>((prevGroups, group) => {
      const groupKey: string = Object.keys(group)[0]

      const containers = group[groupKey].reduce<TGroup[]>((prevContainers, container) => {
        const fields = container.fields.reduce<TField[]>((prevFields, field) => {
          if (field.isRequired && !field.input.isValid && !field.input.onBlur()) {
            isAllValid = false
            return prevFields
          }

          if (field.input.value) {
            return prevFields.concat({
              title: field.label,
              value: field.input.value,
              isRightPdfOutput: field.isRightPdfOutput
            })
          } else {
            return prevFields
          }
        }, [])

        return prevContainers.concat({
          title: container.label,
          placeholder: container.placeholder,
          separator: container.separator,
          isGridOutput: container.isGridOutput,
          questions: [...fields]
        })
      }, [])

      return { ...prevGroups, [groupKey]: containers }
    }, {})

    if (!isAllValid) {
      return {}
    }

    return values
  }

  return {
    handleSubmit
  }
}
