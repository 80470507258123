import React from 'react'

import { useDimensions } from 'shared/dimensions'
import { InputReturn } from 'shared/hooks'
import { getStyles, styled, theme } from 'ui/styles'

import { RadioGroup } from './radio-group'

const BREAKPOINT = 1000

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    align-items: start;
  }
`

const StyledTextArea = styled.input<{ isWithFile?: boolean }>`
  ${getStyles('t1')}
  width: 100%;
  height: 42px;
  border-radius: 8px;
  padding: 8px ${({ isWithFile }) => (isWithFile ? 42 : 16)}px 8px 16px;
  margin-right: 36px;

  border: 1px solid ${theme.palette.light_gray};

  color: ${theme.palette.dark_blue};
  transition-property: border, color;
  transition-duration: ${theme.transition.hover}ms;

  &::placeholder {
    color: ${theme.palette.blue_gray};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

type RowProps = {
  input: InputReturn
  onChange: (value: string) => void
  customLabels?: string[]
}

export const PickerInput: React.FC<RowProps> = ({ input, onChange, customLabels = ['Рубли', 'Доллары', 'Евро'] }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const [textAreaValue, setTextAreaValue] = React.useState('')

  const onCurrencyPicker = (value: string) => {
    onChange(`${textAreaValue} ${value}`)
  }

  return (
    <Wrapper isMobile={isMobile}>
      <StyledTextArea
        spellCheck={false}
        type="number"
        value={textAreaValue}
        onChange={(e) => setTextAreaValue(e.target.value)}
        onBlur={input.onBlur}
      />
      <RadioGroup
        customLabels={customLabels}
        initialChecked={customLabels[0]}
        onChange={onCurrencyPicker}
        isAlwaysInRow
      />
    </Wrapper>
  )
}
