import React from 'react'

import { useDimensions } from 'shared/dimensions'
import { styled, theme } from 'ui/styles'

type MobileUpperFrameProps = {
  openingParameter: boolean
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  z-index: 99;
  opacity: 0;
  background-color: ${theme.palette.dark_blue};

  transition: transform ${theme.transition.hover}ms ease;
  transform: translateY(100%);

  &.open {
    transform: translateY(0);
    opacity: 1;
  }
`

const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
`

export const MobileUpperFrame: React.FC<MobileUpperFrameProps> = ({ openingParameter, children }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const [isOpening, setIsOpening] = React.useState(openingParameter)

  const isOpen = React.useMemo(() => openingParameter && isMobile, [openingParameter, isMobile])

  React.useEffect(() => {
    setIsOpening(openingParameter)
  }, [openingParameter])

  if (!isOpen) {
    return null
  }

  return (
    <Wrapper className={isOpening ? 'open' : ''}>
      <Content>{children}</Content>
    </Wrapper>
  )
}
