import { ActionType, createReducer } from 'typesafe-actions'

import * as actions from './actions'

export type StatusState = {
  activeStatusModal: boolean
}

const initialState: StatusState = {
  activeStatusModal: false
}

export type StatusAction = ActionType<typeof actions>

export const statusReducer = createReducer<StatusState>(initialState).handleAction(
  actions.setStatusModalVisible,
  (state: StatusState, { payload }: any) => {
    return { ...state, activeStatusModal: payload }
  }
)
