import React from 'react'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { styled, theme } from 'ui/styles'
import { InputReturn } from 'shared/hooks'

const Input = styled(PhoneInput)`
  .arrow {
    display: none;
  }

  .selected-flag {
    padding: 0 0 0 16px;
    width: auto;

    &.open {
      border-radius: 0px 8px 8px 0px !important;
    }

    &:focus {
      border-radius: 0px 8px 8px 0px !important;
    }

    &:hover {
      border-radius: 0px 8px 8px 0px;
      border-color: white;
    }
  }

  input {
    ::placeholder {
      color: ${theme.palette.blue_gray};
    }
  }
`

const localization = { Russia: 'Россия', Ukraine: 'Украина' }

const phoneInputStyles = (isValid: boolean) => ({
  width: '100%',
  height: '40px',
  borderRadius: '8px',
  border: isValid ? `1px solid ${theme.palette.light_gray}` : `2px solid ${theme.palette.pink_red}`,
  color: isValid ? theme.palette.dark_blue : theme.palette.pink_red,
  backgroundColor: theme.palette.white,
  transitionProperty: 'border, color',
  transitionDuration: `${theme.transition.hover}ms`,
  padding: '8px 16px',
  fontSize: '16px',
  fontFamily: 'Montserrat,sans-serif'
})

const phoneButtonStyles = (isValid: boolean) => ({
  right: '0',
  width: '52px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  background: theme.palette.light_gray,
  border: isValid ? `1px solid ${theme.palette.light_gray}` : `2px solid ${theme.palette.pink_red}`
})

const phoneDropDownStyles = {
  left: '-250px'
}

export const PhoneField: React.FC<InputReturn> = ({ isValid, onBlur, onChange }) => (
  <Input
    country="ru"
    regions="europe"
    localization={localization}
    inputStyle={phoneInputStyles(isValid)}
    buttonStyle={phoneButtonStyles(isValid)}
    dropdownStyle={phoneDropDownStyles}
    placeholder="+7 (900) 000-00-00"
    preferredCountries={['ru']}
    onBlur={onBlur}
    onChange={onChange}
  />
)
