import React from 'react'

import { styled, theme } from 'ui/styles'
import { RoundBulletLi, Text } from 'ui/components'

const MainList = styled.ul`
  flex: 1;
`

const SubStepsList = styled.ul`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 8px;
  counter-reset: list;
  font-size: 14px;
  line-height: 20px;
`

const RoundLi = styled(RoundBulletLi)<{ bulletSize?: string }>`
  display: inline-flex;
  width: 100%;
  color: ${theme.palette.white};
  margin-bottom: 8px !important;

  &::before {
    background-color: ${theme.palette.white};
    width: ${({ bulletSize }) => (bulletSize ? bulletSize : '6px')};
    height: ${({ bulletSize }) => (bulletSize ? bulletSize : '6px')};
    margin-right: 12px;
  }

  & div {
    break-inside: avoid;
  }
`

type Step = { step: string; subSteps?: string[] }

type Props = {
  steps: Step[]
}

export const MainSteps: React.FC<Props> = ({ steps }) => (
  <MainList>
    {steps.map((st, i) => (
      <RoundLi key={i} wrap={Boolean(st.subSteps) ? 1 : 0}>
        <Text variant="t3" color={theme.palette.white}>
          {st.step}
        </Text>
        {st.subSteps && (
          <SubStepsList>
            {st.subSteps.map((sub, j) => (
              <RoundLi key={`${i}${j}`} bulletSize="3px">
                {sub}
              </RoundLi>
            ))}
          </SubStepsList>
        )}
      </RoundLi>
    ))}
  </MainList>
)
