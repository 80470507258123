import React from 'react'

import { breakpoints } from 'shared/dimensions'
import { styled, theme } from 'ui/styles'

import { Button } from '../button'
import { Icon } from '../icon'
import { LoadingCircle } from '../loading-circle'
import { Text } from '../text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputWrapper = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.mobile - 1}px) {
  }
`

const StyledInput = styled.input<{ disabled: boolean }>`
  position: absolute;
  opacity: 0;
  width: 220px;
  height: 100%;
  ${({ disabled }) => disabled && 'pointer-events: none'};
`

const StyledButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 40px;
  background-color: transparent;
  color: ${({ disabled }) => (disabled ? theme.palette.light_gray : theme.palette.dark_blue)};

  border-radius: 8px;
  border: 1px solid ${theme.palette.blue_gray};

  cursor: pointer;
  z-index: -1; // todo: 0, перекрывает инпут файла

  @media (hover: hover) {
    &:hover {
      border: 1px solid ${theme.palette.dark_blue};
    }
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const LoadingCircleWrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
`

type Props = {
  title: string
  fileIsUploading: boolean
  disabled: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isPdfFile?: boolean
  isMultiple?: boolean
}

export const FilePickerButton: React.FC<Props> = ({
  title,
  isPdfFile,
  fileIsUploading,
  disabled,
  onChange,
  isMultiple = false
}) => {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

  const onClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.files = null
    }
  }

  return (
    <Wrapper>
      <InputWrapper>
        <StyledInput
          ref={inputRef}
          type="file"
          title=" "
          accept={isPdfFile ? 'application/pdf' : '*'}
          onChange={onChange}
          onClick={onClick}
          multiple={isMultiple}
          disabled={disabled || fileIsUploading}
        />
        <StyledButton disabled={disabled || fileIsUploading}>
          {fileIsUploading ? (
            <LoadingCircleWrapper children={<LoadingCircle isSmall />} />
          ) : (
            <StyledIcon name="attach" color={disabled ? theme.palette.light_gray : theme.palette.blue_gray} />
          )}
          <Text variant="t1">{title}</Text>
        </StyledButton>
      </InputWrapper>
    </Wrapper>
  )
}
