import React from 'react'
import { RootState } from 'root-reducer'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { setStatusModalVisible } from 'store/status/actions'
import { StatusModal, VacancyModal } from 'app/features/modals'
import { QuestionnaireModal } from 'app/features/questionnaire-modal-form'
import { QuestionnaireData } from 'app/data/questionnaire'
import { TagT, VacancyT } from 'app/data/vacancy'
import { breakpoints } from 'shared/dimensions'
import { ModalWindow } from 'shared/modal-window'
import { RoundButton, Text, RoundButtonProps, showContentWithOpacity } from 'ui/components'
import { styled, theme } from 'ui/styles'

import { Sections } from '../molecules'
import { ResponseButton } from '../atoms'
import { SendRequestReturnT, useSendRequest } from 'shared/hooks'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 64px 60px;
  height: fit-content;
  animation: ${showContentWithOpacity} ${theme.transition.hover}ms linear;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding: 60px 32px 64px 32px;
  }
`

const CloseVacancy = styled(RoundButton)`
  position: absolute;
  z-index: 3;
  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

const InformationWrapper = styled.div`
  display: flex;
  max-width: 700px;
  width: 100%;
  flex-direction: column;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    justify-content: start;
  }
`

const StyledText = styled(Text)`
  width: 90%;
  min-width: 200px;
  margin-bottom: 32px;
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`

type CardProps = {
  vacancy: VacancyT
  vacancyKey: TagT | undefined
  isDisplayed: boolean
  onResponseClick(): void
}

export const VacancyCardContainer: React.FC<ContainerProps> = ({
  vacancy,
  isDisplayed,
  onResponseClick,
  setStatusVisible,
  activeStatus
}) => {
  const [cardIsDisplayed, setCardIsDisplayed] = React.useState(isDisplayed)
  const [responseModalVisible, setResponseModalVisible] = React.useState(false)
  const [questionnaireModalVisible, setQuestionnaireModalVisible] = React.useState(false)

  const isQuestionnaireFormExist = QuestionnaireData.questionnaires.find((elem) => {
    return vacancy.vacancyType === elem.vacancyType
  })

  const handleOverlayClick = React.useCallback(() => {
    setResponseModalVisible(false)
    setStatusVisible(false)
    setQuestionnaireModalVisible(false)
  }, [setStatusVisible])

  const handleSubmitForm = React.useCallback(() => {
    setResponseModalVisible(false)
    setQuestionnaireModalVisible(false)
    setStatusVisible(true)
  }, [setStatusVisible])

  const vacancyRequest: SendRequestReturnT = useSendRequest(handleSubmitForm, 'candidateApplications')

  React.useEffect(() => {
    setCardIsDisplayed(isDisplayed)
  }, [isDisplayed])

  if (!cardIsDisplayed) {
    return null
  }

  return (
    <Wrapper>
      <InformationWrapper>
        <CloseVacancy {...RoundButtonProps} iconName="close" onClick={onResponseClick} />
        <StyledText variant="h4" color={theme.palette.white}>
          {vacancy.title}
        </StyledText>
        <Sections data={vacancy} />
        <ButtonWrapper>
          <ResponseButton handleClick={() => setResponseModalVisible(true)} title="Это про меня" />
          {/* {isQuestionnaireFormExist && (
            <ResponseButton handleClick={() => setQuestionnaireModalVisible(true)} title="Анкета соискателя" />
          )} */}
        </ButtonWrapper>
      </InformationWrapper>

      <ModalWindow
        isVisible={responseModalVisible || activeStatus || questionnaireModalVisible}
        onOverlayClick={handleOverlayClick}
        contentMaxWidth={questionnaireModalVisible ? 'clamp(0px, 95vw, 1088px)' : 'clamp(0px, 95vw, 704px)'}
      >
        {responseModalVisible && (
          <VacancyModal onClose={() => setResponseModalVisible(false)} request={vacancyRequest} vacancyData={vacancy} />
        )}
        {questionnaireModalVisible && isQuestionnaireFormExist && (
          <QuestionnaireModal
            vacancyTitle={vacancy.title}
            onClose={() => setQuestionnaireModalVisible(false)}
            onSubmit={handleSubmitForm}
            questionnaire={isQuestionnaireFormExist}
          />
        )}
        {activeStatus && <StatusModal onClose={() => setStatusVisible(false)} isSuccess={true} />}
      </ModalWindow>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeStatus: state.statusModal.activeStatusModal
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStatusVisible: (status: boolean) => dispatch(setStatusModalVisible(status))
})

type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & CardProps

export const VacancyCard = connect(mapStateToProps, mapDispatchToProps)(VacancyCardContainer)
