export const getFileType = (value: string): string => {
  const splitStr = value.split('.')
  return splitStr.slice(0, splitStr.length)[splitStr.length - 1]
}

export const MAX_FILE_SIZE_MB = 25
export const FILE_LIMIT = 5
export const LINK_LIMIT = 10

export const INVALID_FILE_FORMATS: string[] = [
  'exe',
  '0xe',
  'app',
  'apk',
  'bat',
  'ba_',
  'bin',
  'cgi',
  'cmd',
  'com',
  'csh',
  'e_e',
  'esh',
  'ex_',
  'exe1',
  'ezt',
  'isu',
  'nexe',
  'otm',
  'paf',
  'ps1',
  'qit',
  'rgs',
  'scr',
  'script',
  'vdo',
  'vexe',
  'ws',
  'wsf',
  'msi',
  'msu'
]
