import React from 'react'

import { styled } from 'ui/styles'
import { Text } from 'ui/components'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  cursor: pointer;
  height: min-content;
  margin: 4px 0;

  &:not(:last-child) {
    margin-right: 16px;
  }
`

const Button = styled.div<{ isChecked: boolean }>`
  position: relative;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.blue_gray};
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.pink_red};
    transition: opacity ${({ theme }) => theme.transition.hover}ms;
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  }
`

type RadioProps = {
  label: string
  initialChecked: boolean
  onChange: (value: boolean) => void
}

export const RadioButton: React.FC<RadioProps> = ({ label, initialChecked, onChange }) => {
  const [isChecked, setIsChecked] = React.useState(initialChecked)

  React.useEffect(() => {
    setIsChecked(initialChecked)
  }, [initialChecked])

  const handleClick = () => {
    setIsChecked((prev) => {
      onChange(!prev)

      return !prev
    })
  }

  return (
    <Wrapper onClick={handleClick}>
      <Button isChecked={isChecked} />
      <Text variant="t3">{label}</Text>
    </Wrapper>
  )
}
