import React from 'react'

import { getStyles, styled, theme, css } from 'ui/styles'
import { InputReturn, useTextareaHeight } from 'shared/hooks'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`
const TextAreaWrapper = styled.div<{ isValid: boolean; enableFocusColor?: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 0px
    ${({ isValid }) => (isValid ? `1px ${theme.palette.light_gray}` : `2px ${theme.palette.pink_red}`)};
  box-sizing: border-box;
  overflow: hidden;
  min-height: 40px;
  transition-property: box-shadow, color;
  transition-duration: ${theme.transition.hover}ms;
  ${({ enableFocusColor }) =>
    enableFocusColor &&
    css`
      &:has(textarea:focus) {
        border: 1px solid ${theme.palette.dark_pink_red};
      }
    `}
`

const StyledTextArea = styled.textarea<{ enableFocusColor?: boolean }>`
  ${getStyles('t1')}
  align-self: start;
  width: 100%;
  padding: 8px 16px 8px 16px;

  &::placeholder {
    color: ${theme.palette.blue_gray};
  }

  @-moz-document url-prefix() {
    textarea {
      height: 5em;
    }
  }
`

type Props = {
  input: InputReturn
  placeholder?: string
  enableFocusColor?: boolean
  isPdfFile?: boolean
  rows?: number
}

export const TextArea: React.FC<Props> = ({ input, placeholder, isPdfFile, enableFocusColor = false, rows = 1 }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  useTextareaHeight(textareaRef, input.value)

  return (
    <Wrapper>
      <TextAreaWrapper isValid={input.isValid} enableFocusColor={enableFocusColor}>
        <StyledTextArea
          ref={textareaRef}
          spellCheck={false}
          placeholder={placeholder}
          rows={rows}
          disabled={isPdfFile}
          {...input}
        />
      </TextAreaWrapper>
    </Wrapper>
  )
}
