import React from 'react'

import { styled } from 'ui/styles'
import { LetterBulletLi, RoundBulletLi, Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'

const MainList = styled.ul`
  margin-top: 24px;
  columns: 2;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    columns: 1;
  }
`

const SubStepsList = styled.ol`
  width: 100%;
  margin-left: 24px;
  counter-reset: list;
`

type Step = { step: string; subSteps?: string[] }

type Props = {
  steps: Step[]
}

export const MainStepsList: React.FC<Props> = ({ steps }) => (
  <MainList>
    {steps.map((st, i) => (
      <RoundBulletLi key={i} wrap={Boolean(st.subSteps) ? 1 : 0}>
        <Text variant="t1">{st.step}</Text>
        {st.subSteps && (
          <SubStepsList>
            {st.subSteps.map((sub, j) => (
              <LetterBulletLi key={`${i}${j}`}>{sub}</LetterBulletLi>
            ))}
          </SubStepsList>
        )}
      </RoundBulletLi>
    ))}
  </MainList>
)
