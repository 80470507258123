import React from 'react'
import { connect } from 'react-redux'

import { styled, theme, css } from 'ui/styles'
import { HBox, Icon, Image, MobileUpperFrame, PrimaryButton, Text } from 'ui/components'

import { VACANCY, TagT, VACANCY_KEYS, VacancyT } from 'app/data/vacancy'
import { ModalWindow } from 'shared/modal-window'
import { StatusModal, VacancyModal } from 'app/features/modals'
import { RootState } from 'root-reducer'
import { setActiveTag } from 'store/tag/actions'
import { setStatusModalVisible } from 'store/status/actions'
import { SendRequestReturnT, useSendRequest } from 'shared/hooks'
import { VacancyCard } from 'app/features/vacancy-card/organisms'

const BREAKPOINT_2K = 2560

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  height: 100%;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TagsList = styled.ul`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
`

const Tag = styled.li<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: ${({ active }) => (active ? theme.palette.pink_red : 'inherit')};
  width: fit-content;
  transition: color ${theme.transition.hover}ms;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  * svg * {
    stroke: ${theme.palette.pink_red};
    transition: stroke ${theme.transition.hover}ms linear;
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.palette.dark_pink_red};
    }
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CareerButton = styled(PrimaryButton)`
  display: flex;
  width: 100%;

  @media (min-width: ${BREAKPOINT_2K - 1}px) {
    width: 283px;
  }
`

const VacancyCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: overlay;
`

const SingleImage = styled(Image)<{ active: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      filter: blur(12px);
    `}
`

const ImageFilter = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;

  background: rgba(42, 61, 87, 0.9);
  mix-blend-mode: normal;
  z-index: -1;
`

const CareerContainer: React.FC<ContainerProps> = ({ activeTag, setActiveVacancy, activeStatus, setStatusVisible }) => {
  const [vacancyModalVisible, setVacancyModalVisible] = React.useState(false)

  const vacancyTags = React.useMemo(
    () =>
      (VACANCY_KEYS as TagT[]).map((tag, i) => ({
        key: tag,
        value: i,
        label: VACANCY[tag][0].title
      })),
    []
  )

  const activeVacancies: VacancyT[] = React.useMemo(() => {
    return (Object.keys(VACANCY) as (keyof typeof VACANCY)[]).reduce((curArr: VacancyT[], key) => {
      curArr.push(...VACANCY[key].map((el) => ({ ...el, key })))
      return curArr
    }, [])
  }, [])

  const mobileVacancyIsDisplayed: boolean = React.useMemo(
    () => Boolean(activeTag && activeTag === VACANCY_KEYS[Object.keys(VACANCY).indexOf(activeTag!)]),
    [activeTag]
  )

  const handleVacancyTagClick = (tag: TagT) => {
    if (tag in VACANCY) {
      setActiveVacancy(tag)
    }
  }

  const handleSubmitVacancy = () => {
    setVacancyModalVisible(false)
    setStatusVisible(true)
  }

  const request: SendRequestReturnT = useSendRequest(handleSubmitVacancy, 'candidateApplications')

  return (
    <Wrapper>
      <ContentWrapper>
        <Text variant="h3">Сейчас ищем</Text>
        <TagsList>
          {vacancyTags.map((tag, i) => (
            <Tag key={i} active={activeTag === tag.key} onClick={() => handleVacancyTagClick(tag.key)}>
              <StyledIcon name="arrows/right" />
              <Text variant="t1">{tag.label}</Text>
            </Tag>
          ))}
        </TagsList>
      </ContentWrapper>
      <ButtonWrapper>
        <CareerButton onClick={() => setVacancyModalVisible(true)}>Хочу работать у вас</CareerButton>
        <HBox height={64} />
      </ButtonWrapper>
      <ModalWindow
        isVisible={vacancyModalVisible || activeStatus}
        onOverlayClick={() => {
          setVacancyModalVisible(false)
        }}
        contentMaxWidth="clamp(0px, 95vw, 704px)"
      >
        {activeStatus && <StatusModal onClose={() => setStatusVisible(false)} isSuccess={true} />}

        {vacancyModalVisible && <VacancyModal onClose={() => setVacancyModalVisible(false)} request={request} />}
      </ModalWindow>

      <MobileUpperFrame openingParameter={Boolean(activeTag?.length)}>
        <VacancyCardWrapper>
          <SingleImage name="career-bg.webp" width="100%" height="100%" fit="cover" />
          <ImageFilter />
          <VacancyCard
            isDisplayed={mobileVacancyIsDisplayed}
            vacancy={activeVacancies[Object.keys(VACANCY).indexOf(activeTag!)]}
            vacancyKey={activeTag}
            onResponseClick={() => {
              setActiveVacancy()
            }}
          />
        </VacancyCardWrapper>
      </MobileUpperFrame>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeTag: state.tag.activeTag,
  activeStatus: state.statusModal.activeStatusModal
})

const mapDispatchToProps = (dispatch: any) => ({
  setActiveVacancy: (tag?: TagT) => dispatch(setActiveTag(tag)),
  setStatusVisible: (status: boolean) => dispatch(setStatusModalVisible(status))
})

type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const Career = connect(mapStateToProps, mapDispatchToProps)(CareerContainer)
