import React from 'react'

import { styled } from 'ui/styles'
import { Icon, RoundButton, Separator, ModalButtonProps, Loader } from 'ui/components'
import { useDimensions } from 'shared/dimensions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const CloseButton = styled(RoundButton)``

const CloseIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 0;
`

type Props = {
  onClose(): void
  header?: React.ReactNode
  isLoading?: boolean
}

export const Modal: React.FC<Props> = ({ header, onClose, isLoading = false, children }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <Wrapper>
      {isLoading && <Loader />}
      {header && (
        <>
          <HeaderWrapper>
            <TitleWrapper>{header}</TitleWrapper>
            {isMobile ? (
              <CloseIcon name="close" onClick={onClose} />
            ) : (
              <CloseButton {...ModalButtonProps} onClick={onClose} />
            )}
          </HeaderWrapper>
          <Separator />
        </>
      )}
      {children}
    </Wrapper>
  )
}
