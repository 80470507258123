import React from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

import { styled, theme } from 'ui/styles'
import { useDimensions } from 'shared/dimensions'
import { ContactsBar } from '.'

type YandexMapProps = {
  onMapClose?(): void
  onMapClick?(): void
  isOpened?: boolean
}

const marksCoordinates = [[57.654475, 39.867780]]

const defaultState = (isMobile: boolean) => ({
  center: [57.654475, 39.867780],
  zoom: isMobile ? 14 : 16
})

const defaultOptions = {
  preset: 'islands#redHomeIcon',
  iconColor: theme.palette.pink_red
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: ${theme.palette.light_gray};

  .ymaps-2-1-79-map {
    border-radius: 12px;
  }

  ymaps[class$='-inner-panes'] {
    border-radius: 12px;
  }
`

export const YandexMap: React.FC<YandexMapProps> = ({ onMapClose, onMapClick, isOpened }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <Wrapper>
      <YMaps>
        <Map defaultState={defaultState(isMobile)} width="100%" height="100%" fit="cover" onClick={onMapClick}>
          {marksCoordinates.map((coordinate, i) => (
            <Placemark key={i} geometry={coordinate} defaultOptions={defaultOptions} onClick={onMapClick} />
          ))}
        </Map>
        {isOpened && <ContactsBar onClose={onMapClose} />}
      </YMaps>
    </Wrapper>
  )
}
