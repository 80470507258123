import React from 'react'

import { styled } from 'ui/styles'
import { LoadingCircle } from './loading-circle'

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  width: 105%;
  height: 101%;
  z-index: 2;

  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);

  /* for safari mobile */
  align-self: center;
  justify-content: center;
  align-items: center;
`

export const Loader: React.FC = () => (
  <Wrapper>
    <LoadingCircle />
  </Wrapper>
)
