export const theme = {
  palette: {
    white: '#FFFFFF',
    light_gray: '#E3E4EB',
    blue_gray: '#B8BCCC',
    dark_pink_red: '#B61032',
    pink_red: '#C92533',
    dark_blue: '#2A3D57',
    black: '#000000'
  },
  fontFamily: {
    montserrat: 'Montserrat'
  },
  transition: {
    hover: 200,
    mainMenu: 300,
    slide: 800,
    spin: 1200
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '40px',
      lineHeight: '56px',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '32px',
      lineHeight: '39px',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 'bold'
    },
    h6: {
      fontSize: '18px',
      fontWeight: 'normal'
    },
    h7: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '500'
    },
    t1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal'
    },
    t2: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold'
    },
    t3: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'normal'
    },
    t4: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold'
    },
    t6: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 'normal'
    },
    t_12_20: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 'normal'
    },
    t_14_24: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  }
}
export type Theme = typeof theme
export type WithTheme = { theme: Theme }
