import React from 'react'

import { RadioButton } from 'ui/components'
import { styled, css } from 'ui/styles'
import { breakpoints } from 'shared/dimensions'

const Wrapper = styled.div<{ isTakeAllSpace: boolean; isInColumn: boolean; isQuiz: boolean }>`
  display: flex;
  flex-direction: ${({ isQuiz }) => (isQuiz ? 'column' : 'row')};
  justify-content: ${({ isInColumn }) => (isInColumn ? 'start' : 'space-between')};
  margin: 8px 0px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
  }

  ${({ isTakeAllSpace }) =>
    isTakeAllSpace &&
    css`
      width: 100%;
    `};
`

const RadioWrapper = styled.div<{
  isAlwaysInRow?: boolean
  isAlwaysInColumn?: boolean
  isQuiz?: boolean
}>`
  display: flex;
  flex-direction: ${({ isAlwaysInColumn }) => (isAlwaysInColumn ? 'column' : 'row')};

  ${({ isQuiz }) =>
    isQuiz &&
    css`
      justify-content: center;
      margin-top: 24px;
    `}

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: ${({ isAlwaysInRow, isAlwaysInColumn }) => (isAlwaysInRow && !isAlwaysInColumn ? 'row' : 'column')};
  }
`

type RadioProps = {
  initialChecked?: string
  onChange: (value: string) => void
  customLabels?: string[]
  isInColumn?: boolean
  isQuiz?: boolean
  isAlwaysInRow?: boolean
  isAlwaysInColumn?: boolean
  isTakeAllSpace?: boolean
}

export const RadioGroup: React.FC<RadioProps> = ({
  onChange,
  isInColumn = true,
  initialChecked,
  isQuiz = false,
  isAlwaysInRow = true,
  isAlwaysInColumn = false,
  isTakeAllSpace = true,
  customLabels = ['0', '1', '2', '3']
}) => {
  const initialIndex = customLabels.findIndex((el) => el === initialChecked)

  const [activeCheckedIndex, setActiveCheckedIndex] = React.useState(initialIndex)

  React.useEffect(() => {
    setActiveCheckedIndex(initialIndex)
  }, [initialIndex])

  const handleChange = (value: boolean, i: number, curLabel: string) => {
    setActiveCheckedIndex(value ? i : -1)
    onChange(value ? curLabel : '')
  }

  return (
    <Wrapper isInColumn={isInColumn} isQuiz={isQuiz} isTakeAllSpace={isTakeAllSpace}>
      <RadioWrapper isAlwaysInRow={isAlwaysInRow} isAlwaysInColumn={isAlwaysInColumn} isQuiz={isQuiz}>
        {customLabels.map((currentLabel, i) => (
          <RadioButton
            key={i}
            label={currentLabel}
            initialChecked={i === activeCheckedIndex}
            onChange={(value) => handleChange(value, i, currentLabel)}
          />
        ))}
      </RadioWrapper>
    </Wrapper>
  )
}
