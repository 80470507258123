import React from 'react'

import { styled, theme } from 'ui/styles'
import { RoundBulletLi, Text } from 'ui/components'
import { breakpoints } from 'shared/dimensions'

const MainList = styled.ul`
  margin-bottom: 24px;
  display: flex;
  gap: 64px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
    gap: 16px;
  }
`

const UnitLi = styled(RoundBulletLi)`
  margin-bottom: 0 !important;

  div {
    white-space: nowrap;
  }
`

type Props = {
  units: string[]
}

export const MainUnitsList: React.FC<Props> = ({ units }) => (
  <MainList>
    {units.map((un, i) => (
      <UnitLi key={i} wrap={0}>
        <Text variant="t2" color={theme.palette.pink_red}>
          {un}
        </Text>
      </UnitLi>
    ))}
  </MainList>
)
