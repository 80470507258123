import vacanciesJson from './vacancies.json'

export type TagT = keyof typeof vacanciesJson

export type VacancyT = {
  vacancyId: string
  title: string
  description: VacancyDescT[]
  vacancyType?: string
}

export type VacancyDescT = {
  title: string
  content: string[]
}

export const VACANCY: Record<string, VacancyT[]> = JSON.parse(JSON.stringify(vacanciesJson))

export const VACANCY_KEYS: string[] = Object.keys(VACANCY)
