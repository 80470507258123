import React from 'react'

import { FILE_LIMIT, getFileType, INVALID_FILE_FORMATS, LINK_LIMIT, MAX_FILE_SIZE_MB } from 'app/data/files'

import { DataFileT, useUploadFile } from './useUploadFile'

export enum InputType {
  Default = 'default',
  Initials = 'initials',
  Phone = 'phone',
  Email = 'email',
  Number = 'number',
  Radio = 'radio',
  Checkbox = 'checkbox'
}

type InputProps = {
  initialValue?: string
  inputType?: InputType
  isRequired?: boolean
  isPhoneField?: boolean
  validate?(value: string): boolean
  formatOnBlur?(val: string): string
}

export type InputReturn = {
  value: string
  secondaryValue: string[]
  isValid: boolean
  onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string): void
  handlerSecondaryChange(value: string[], removingIndex?: number): void
  onBlur(): boolean
  handlerFileLoad(file: FileList, isNotMultiple?: boolean): void
  clearField(val?: boolean): void
  removeFile(fileIndex: number): void
  file?: DataFileT[]
  isPhoneField?: boolean
  fileIsUploading?: boolean
}

const defaultValidate = (val: string) => !!val

export const useInput: (props: InputProps) => InputReturn = ({
  initialValue = '',
  isPhoneField = false,
  inputType = InputType.Default,
  isRequired,
  validate = isRequired ? defaultValidate : () => true,
  formatOnBlur
}) => {
  const [value, setValue] = React.useState<string>(initialValue)
  const [secondaryValue, setSecondaryValue] = React.useState<string[]>([])
  const [file, setFile] = React.useState<DataFileT[] | undefined>(undefined)
  const [isValid, setIsValid] = React.useState<boolean>(true)

  const uploadingFiles = useUploadFile()

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const mutableValue = typeof e === 'string' ? e : e.target.value

    switch (inputType) {
      case InputType.Default:
        setValue(mutableValue)
        break
      case InputType.Initials:
        setValue(mutableValue.replaceAll(/[^a-zA-Zа-яёА-ЯЁ' -]/g, ''))
        break
      case InputType.Number:
        setValue(mutableValue.replaceAll(/[^0-9.,]/g, ''))
        break
      case InputType.Phone:
        setValue(mutableValue)
        break
      case InputType.Radio:
        setValue(mutableValue)
        break
      case InputType.Checkbox:
        setValue(mutableValue)
        break
      default:
        setValue(mutableValue.trim())
        break
    }

    if (!isValid || !isRequired) {
      // if at least one file is not valid
      if (file && file.length) {
        if (file.some((item) => !item.isValid)) {
          setIsValid(false)
          return
        }
      }
      setIsValid(true)
    }
  }

  const handlerSecondaryChange = (newValue: string[], removingIndex?: number) => {
    if (!removingIndex && newValue.length + secondaryValue.length > LINK_LIMIT) {
      setIsValid(false)
      return
    }

    setSecondaryValue((prev) => {
      if (removingIndex! >= 0) {
        prev = prev.filter((_, i) => i !== removingIndex)
      } else {
        prev = prev.concat(newValue)
      }
      return prev
    })
  }

  const onBlur = () => {
    if (file && file.length) {
      return true
    } else {
      const isCurrentlyValid: boolean = Boolean(validate(value) || Boolean(!isRequired && !value.length))

      setValue((prev) => {
        const trimmed = prev.trim()
        return formatOnBlur ? formatOnBlur(trimmed) : trimmed
      })
      setIsValid(isCurrentlyValid)

      return isCurrentlyValid
    }
  }

  const handlerFileLoad = (files: FileList, isNotMultiple?: boolean) => {
    if (!files.length) {
      return
    }

    if (Number(files.length + (file ? file.length : 0)) > FILE_LIMIT) {
      setIsValid(false)
      return
    }

    if (isNotMultiple) {
      setFile(undefined)
    }

    // search invalid files
    const invalidFiles: DataFileT[] = Array.from(files).reduce<DataFileT[]>((prev, cur) => {
      const isInvalid = INVALID_FILE_FORMATS.includes(getFileType(cur.name)) || cur.size >= MAX_FILE_SIZE_MB * 1048576
      if (isInvalid) {
        return prev.concat({ name: cur.name, uuid: '', size: cur.size, isValid: false })
      }
      return prev
    }, [])

    // deleting invalid files from FileList
    const dt = new DataTransfer()
    Array.from(files).forEach((item, i) => {
      const isIncludeInvalidFile = invalidFiles.some((el) => el.name === item.name)
      if (!isIncludeInvalidFile) {
        dt.items.add(files[i])
      }
    })
    files = dt.files

    const onFilesUpload = (result: DataFileT[]) => {
      const combinedFiles = result.concat(invalidFiles)
      const allFilesIsValid = Boolean(combinedFiles && combinedFiles.some((el) => el.isValid)) && !invalidFiles.length

      setFile((prev) => {
        setIsValid(prev ? prev.every((item) => item.isValid) && allFilesIsValid : allFilesIsValid)
        return prev && prev.length ? prev.concat(combinedFiles) : combinedFiles
      })
    }

    uploadingFiles.upload({ onFilesUpload, files })
  }

  const removeFile = (fileIndex: number) => {
    const filteredFiles = file?.filter((el, index) => index !== fileIndex)
    setFile(filteredFiles)

    if (filteredFiles && filteredFiles.length) {
      const isAllValid: boolean = filteredFiles!.every((el) => Boolean(el.isValid))
      setIsValid(isAllValid)
    } else {
      setIsValid(true)
    }
  }

  const clearField = (isOnlyValue?: boolean) => {
    setValue('')
    if (!isOnlyValue) {
      setFile(undefined)
      setSecondaryValue([])
    }
  }

  return {
    value,
    secondaryValue,
    handlerSecondaryChange,
    file,
    isValid,
    isPhoneField,
    fileIsUploading: uploadingFiles.isLoading,
    onChange,
    onBlur,
    handlerFileLoad,
    clearField,
    removeFile
  }
}
