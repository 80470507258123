import React, { useLayoutEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'root-reducer'
import { setActiveSection } from 'store/project/popup/actions'

import { breakpoints, useDimensions } from 'shared/dimensions'
import { RoundButtonProps, styled, theme } from 'ui/styles'
import { HBox, RoundButton, showContentWithOpacity, Text } from 'ui/components'
import {
  OUTSOURCE_MAIN_STEPS,
  OUTSOURCE_TEAM,
  OUTSOURCE_TEAM_ADAPTIVE,
  OUTSTAFF_MAIN_STEPS,
  OUTSTAFF_TEAM,
  OUTSTAFF_TEAM_ADAPTIVE
} from 'app/data/teams'

import { MainSteps } from '../molecules/main-steps'
import { Team } from '../molecules/team'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 64px 56px;
  z-index: 100;
  backdrop-filter: blur(12px);
  background: rgba(42, 61, 87, 0.95);
  animation: ${showContentWithOpacity} ${theme.transition.hover}ms linear;

  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0 32px 56px;
  }
`

const Content = styled.div`
  max-width: 850px;
  min-height: 1600px;
`

const HeaderWrapper = styled.div`
  padding-top: 56px;

  display: flex;
  flex-direction: column;
  & > div:first-child {
    margin-bottom: 12px;
  }

  :not(:first-of-type) {
    padding-top: 64px;
  }
`

const StyledText = styled(Text)`
  margin-top: 32px;
  margin-bottom: 24px;
`

const MainStepsColumns = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    flex-direction: column;
  }
`

const CloseButton = styled(RoundButton)<{ isMobile?: boolean }>`
  position: fixed;
  cursor: pointer;
  z-index: 1000;
  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

const OutsourceOutstaffPopup: React.FC<PopupProps> = ({ closePopup, activeSection, setActivePopup }) => {
  const { type, isDesktop } = useDimensions()

  const topRef = useRef<HTMLDivElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    switch (activeSection) {
      case 1:
        topRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
        break
      case 2:
        topRef.current?.scrollBy({ top: bottomRef.current?.offsetTop, behavior: 'smooth' })
        break
    }
  }, [activeSection])

  const outsourceMainStepsColumnLeft = [OUTSOURCE_MAIN_STEPS[0]]
  const outsourceMainStepsColumnRight = OUTSOURCE_MAIN_STEPS.slice(1)

  const outstaffMainStepsColumnLeft = OUTSTAFF_MAIN_STEPS.slice(0, 3)
  const outstaffMainStepsColumnRight = OUTSTAFF_MAIN_STEPS.slice(3)

  return (
    <Wrapper ref={topRef}>
      <CloseButton onClick={closePopup} {...RoundButtonProps} isMobile={type === 'mobile'} />
      <Content>
        <HeaderWrapper>
          <Text variant="h3" color={theme.palette.white} height="39px">
            OUTSOURCE
          </Text>
          <Text bold variant="t3" color={theme.palette.white}>
            Разрабатываем проекты под ключ по бизнес-требованиям заказчика в сжатые сроки.
          </Text>
        </HeaderWrapper>
        <StyledText bold variant="t1" color={theme.palette.white}>
          Основные этапы:
        </StyledText>
        <MainStepsColumns>
          <MainSteps steps={outsourceMainStepsColumnLeft} />
          <MainSteps steps={outsourceMainStepsColumnRight} />
        </MainStepsColumns>
        <StyledText bold variant="t1" color={theme.palette.white}>
          Готовая команда с отлаженным процессом разработки:
        </StyledText>
        {isDesktop ? <Team team={OUTSOURCE_TEAM} /> : <Team team={OUTSOURCE_TEAM_ADAPTIVE} />}

        <HeaderWrapper ref={bottomRef}>
          <Text variant="h3" color={theme.palette.white}>
            OUTSTAFF
          </Text>
          <Text bold variant="t3" color={theme.palette.white}>
            Рекомендуем команду для разработки отдельных функций и выделенных стримов.
          </Text>
        </HeaderWrapper>
        <StyledText bold variant="t1" color={theme.palette.white}>
          Основные этапы:
        </StyledText>
        <MainStepsColumns>
          <MainSteps steps={outstaffMainStepsColumnLeft} />
          <MainSteps steps={outstaffMainStepsColumnRight} />
        </MainStepsColumns>
        <StyledText bold variant="t1" color={theme.palette.white}>
          Outstaff-команда
        </StyledText>
        {isDesktop ? <Team team={OUTSTAFF_TEAM} /> : <Team team={OUTSTAFF_TEAM_ADAPTIVE} />}
        <HBox height={56} />
      </Content>
    </Wrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeSection: state.popup.activeSection
})

const mapDispatchToProps = (dispatch: any) => ({
  setActivePopup: (activeSection: number) => dispatch(setActiveSection(activeSection))
})

export type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type PopupProps = {
  closePopup?(): void
  activeSection?: any
} & ContainerProps

export const OutsourceOutstaff = connect(mapStateToProps, mapDispatchToProps)(OutsourceOutstaffPopup)
