import { styled } from 'ui/styles'
import { RedButton } from '.'

export const PrimaryButton = styled(RedButton)`
  color: ${({ theme }) => theme.palette.pink_red};
  border-color: ${({ theme }) => theme.palette.pink_red};
  background: ${({ theme }) => theme.palette.white};

  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.palette.pink_red};
    }
  }
`
