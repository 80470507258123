import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition, Transition } from 'react-transition-group'
import { css } from 'styled-components'

import { breakpoints } from 'shared/dimensions'
import { RoundButtonProps, styled, theme } from 'ui/styles'
import { RoundButton } from 'ui/components'

import { ProjectSlide } from '../molecules'
import { SingleImage } from '../molecules/project-slide'

const defaultImageFilterGradient =
  'linear-gradient(360deg, rgba(42, 61, 87, 0.5) 0%, rgba(42, 61, 87, 0) 32.32%), rgba(42, 61, 87, 0.12)'
const onHoverImageFilterGradient =
  'linear-gradient(0deg, rgba(42, 61, 87, 0.5) 0%, rgba(42, 61, 87, 0) 56.04%), rgba(42, 61, 87, 0.12)'
const onActiveImageFilterGradient =
  'linear-gradient(0deg, #2A3D57 0%, rgba(42, 61, 87, 0) 173.84%), rgba(42, 61, 87, 0.12)'
const transitionTimeout = 1000

const CloseButton = styled(RoundButton)`
  position: absolute;
  z-index: 20;

  top: 32px;
  right: 32px;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    top: 20px;
    right: 20px;
  }
`

const ArrowButton = styled(RoundButton)<{ position: 'left' | 'right'; isDisplayed?: boolean }>`
  position: absolute;
  z-index: 3;
  top: 50%;
  opacity: 0.75;

  ${({ position }) => `${position}: 32px`};
  transform: ${({ position, isDisplayed }) =>
    position === 'left' && isDisplayed
      ? 'translate(-120px, -50%)'
      : position === 'right' && isDisplayed
      ? 'translate(120px, -50%)'
      : 'translateY(-50%)'};
`

const ImageFilter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${defaultImageFilterGradient};
  transition: opacity 300ms;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${onHoverImageFilterGradient};
    opacity: 0;
    transition: opacity 400ms;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${onActiveImageFilterGradient};
    opacity: 0;
    transition: opacity 400ms;
  }
`

const Wrapper = styled.div<{ isShowDescription?: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100vh;

  ${({ isShowDescription }) =>
    !isShowDescription &&
    css`
      cursor: pointer;

      & ${CloseButton} {
        transform: translateX(100px);
      }
    `}

  // enter to
  &.popup-enter-done ${ArrowButton} {
    display: hidden;
    transition: translate 300ms;
  }

  &:hover ${ImageFilter}::after {
    transition: opacity 800ms ease-in-out;
    opacity: ${({ isShowDescription }) => (isShowDescription ? '0' : '1')};
  }

  ${ImageFilter}::before {
    opacity: ${({ isShowDescription }) => (isShowDescription ? '1' : '0')};
  }

  &:hover ${SingleImage} {
    transform: ${({ isShowDescription }) => (isShowDescription ? 'scale(1)' : 'scale(1.1)')};
    transition-property: transform ${({ isShowDescription }) => (isShowDescription ? '400ms' : '1100ms')};
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`

const roundButtonProps = {
  bgColor: {
    original: `${theme.palette.blue_gray}CC`,
    hover: theme.palette.blue_gray
  },
  iconColor: {
    original: theme.palette.white,
    hover: theme.palette.dark_blue
  }
}

export type Slide = {
  image: string
  title: string
  chips: string[]
  onClick?(): void
}

type Props = {
  data: Slide[]
  initialIndex?: number
  onSlideChange?(i: number): void
  setActiveProject(i: number): void
  activeProjectIndex: number
}

const getPrevIndex = (curIndex: number, length: number) => (curIndex ? curIndex - 1 : length - 1)
const getNextIndex = (curIndex: number, length: number) => (curIndex === length - 1 ? 0 : curIndex + 1)

export const Slider: React.FC<Props> = ({
  data,
  initialIndex = 0,
  onSlideChange,
  setActiveProject,
  activeProjectIndex
}) => {
  const [isShowDescription, setShowDescription] = useState(false)
  const [curIndex, setCurIndex] = useState(initialIndex)

  const sliderTimer = useRef<number>(-1)
  const prevIndex = getPrevIndex(curIndex, data.length)
  const nextIndex = getNextIndex(curIndex, data.length)

  const handlePrev = () => {
    setCurIndex(prevIndex)
    onSlideChange && onSlideChange(prevIndex)
  }

  const handleNext = useCallback(() => {
    setCurIndex(nextIndex)
    onSlideChange && onSlideChange(nextIndex)
  }, [nextIndex, onSlideChange])

  useEffect(() => {
    if (isShowDescription) {
      return
    }

    sliderTimer.current = window.setTimeout(handleNext, 3500)
    return () => clearTimeout(sliderTimer.current)
  }, [curIndex, handleNext, isShowDescription])

  const jsxSlider = useMemo(
    () => (
      <SliderWrapper
        onClick={() => {
          setActiveProject(curIndex)
          setShowDescription(true)
        }}
      >
        {data.map((slide, i) => (
          <ProjectSlide
            key={i}
            isDisplayed={i === curIndex}
            data={data}
            curIndex={curIndex}
            slide={slide}
            isShowDescription={isShowDescription}
            transitionTimeout={transitionTimeout}
            activeProjectIndex={activeProjectIndex}
          />
        ))}
        <ImageFilter />
      </SliderWrapper>
    ),
    [activeProjectIndex, curIndex, data, isShowDescription, setActiveProject]
  )

  const setShowDescriptionHandler = useCallback(() => setShowDescription(!isShowDescription), [isShowDescription])

  return (
    <CSSTransition in={isShowDescription} timeout={transitionTimeout} classNames="popup">
      <Wrapper isShowDescription={isShowDescription}>
        <Transition in={isShowDescription} timeout={transitionTimeout} unmountOnExit mountOnEnter>
          <CloseButton onClick={setShowDescriptionHandler} {...RoundButtonProps} />
        </Transition>

        <Transition in={!isShowDescription} timeout={transitionTimeout} unmountOnExit>
          <ArrowButton
            isDisplayed={isShowDescription}
            iconName="arrows/left"
            position="left"
            onClick={handlePrev}
            {...roundButtonProps}
          />
        </Transition>

        {jsxSlider}

        <Transition in={!isShowDescription} timeout={transitionTimeout} unmountOnExit mountOnEnter>
          <ArrowButton
            isDisplayed={isShowDescription}
            {...roundButtonProps}
            iconName="arrows/right"
            position="right"
            onClick={handleNext}
          />
        </Transition>
      </Wrapper>
    </CSSTransition>
  )
}
