import React from 'react'

import { getStyles, styled, theme } from 'ui/styles'
import { FileInput, PhoneField, Text } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { useTextareaHeight, InputReturn } from 'shared/hooks'

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: inline-flex;
  position: relative;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 40px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
`
const StyledText = styled(Text)`
  max-width: 240px;
`

const StyledDescription = styled(Text)`
  max-width: 240px;
  padding: 16px 32px 0 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    padding: 8px 0 0 0;
    max-width: 100%;
  }
`

const TextAreaWrapper = styled.div<{ isValid: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 0px
    ${({ isValid }) => (isValid ? `1px ${theme.palette.light_gray}` : `2px ${theme.palette.pink_red}`)};
  box-sizing: border-box;
  overflow: hidden;
  min-height: 40px;
  transition-property: box-shadow, color;
  transition-duration: ${theme.transition.hover}ms;
`

const StyledTextArea = styled.textarea`
  ${getStyles('t1')}
  align-self: start;
  width: 100%;
  padding: 8px 16px 8px 16px;

  &::placeholder {
    color: ${theme.palette.blue_gray};
  }

  @-moz-document url-prefix() {
    textarea {
      height: 5em;
    }
  }
`

type FormRowProps = {
  label: string
  input: InputReturn
  labelDescription?: string
  placeholder?: string
  isWithFile?: boolean
  isPdfFile?: boolean
  isMultiple?: boolean
  isWithoutTextArea?: boolean
}

export const FormRow: React.FC<FormRowProps> = ({
  label,
  placeholder,
  input,
  isWithFile,
  isPdfFile,
  isMultiple,
  isWithoutTextArea,
  labelDescription
}) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  useTextareaHeight(textareaRef, input.value)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      input.handlerFileLoad(event.target.files, !isMultiple)
    }
  }

  return (
    <Wrapper isMobile={isMobile}>
      <Label>
        <StyledText variant={isMobile ? 't1' : 't2'} color={input.isValid ? 'inherit' : theme.palette.pink_red}>
          {label}
        </StyledText>
        {Boolean(labelDescription) && (
          <StyledDescription variant="t6" color={input.isValid ? theme.palette.blue_gray : theme.palette.pink_red}>
            {labelDescription}
          </StyledDescription>
        )}
      </Label>
      {input.isPhoneField ? (
        <PhoneField {...input} />
      ) : isWithFile ? (
        <FileInput
          input={input}
          placeholder={placeholder}
          handleChange={handleChange}
          isValid={input.isValid}
          isWithoutTextArea={isWithoutTextArea}
          isPdfFile={isPdfFile}
          isMultiple={isMultiple}
        />
      ) : (
        <TextAreaWrapper isValid={input.isValid}>
          <StyledTextArea
            ref={textareaRef}
            spellCheck={false}
            placeholder={placeholder}
            rows={1}
            disabled={isPdfFile}
            {...input}
          />
        </TextAreaWrapper>
      )}
    </Wrapper>
  )
}
