import React, { useEffect, useRef, useState } from 'react'

import { styled, theme } from 'ui/styles'
import { RoundBulletLi, Text } from 'ui/components'

const MainList = styled.ul<{ breakpoint: number }>`
  display: flex;
  align-items: baseline;
  gap: 64px;

  @media (max-width: ${({ breakpoint }) => breakpoint}px) {
    flex-direction: column;
    gap: 0;
  }
`

const MemberLi = styled(RoundBulletLi)<{ childDivWidth: number; breakpoint: number }>`
  position: relative;

  :not(:last-child)&::after {
    display: block;
    content: '';
    position: absolute;
    top: 11.5px;
    right: -64px;
    height: 1px;
    width: max(64px, calc(100% + 24px - ${({ childDivWidth }) => childDivWidth}px));
    background-color: ${theme.palette.pink_red};

    @media (max-width: ${({ breakpoint }) => breakpoint}px) {
      display: none;
    }
  }
`

const WorkersList = styled.ul`
  width: 100%;
  margin-top: 16px;
`

const WorkerLi = styled(RoundBulletLi)`
  position: relative;

  div {
    white-space: nowrap;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: -32px;
    left: 3.5px;
    height: 42px;
    width: 1px;
    background-color: ${theme.palette.pink_red};
  }
`

type TeamMember = { lead: string; workers: { position: string; optional?: boolean }[] }

type Props = {
  team: TeamMember[]
  widthBreakpoint: number
}

export const TeamList: React.FC<Props> = ({ team, widthBreakpoint }) => {
  const [width, setWidth] = useState(0)
  const textRefs = useRef<React.RefObject<HTMLDivElement>[]>([])

  if (textRefs.current.length !== team.length) {
    textRefs.current = team.map((_, i) => textRefs.current[i] || React.createRef())
  }

  useEffect(() => {
    setWidth(64)
  }, [])

  return (
    <MainList breakpoint={widthBreakpoint}>
      {team.map((member, i) => (
        <MemberLi
          key={i}
          childDivWidth={textRefs.current[i]?.current?.clientWidth || width}
          breakpoint={widthBreakpoint}
          wrap={1}
        >
          <Text divRef={() => textRefs.current[i]} variant="t2">
            {member.lead}
          </Text>
          <WorkersList>
            {member.workers.map((worker, j) => (
              <WorkerLi wrap={0} key={`${i}${j}`}>
                <Text variant="t1" color={worker.optional ? theme.palette.blue_gray : 'inherit'}>
                  {worker.position}
                </Text>
              </WorkerLi>
            ))}
          </WorkersList>
        </MemberLi>
      ))}
    </MainList>
  )
}
