import { FILE_LIMIT, LINK_LIMIT } from 'app/data/files'
import React from 'react'

import { InputReturn } from 'shared/hooks'
import { Button, Text, Icon } from 'ui/components'
import { styled, theme, css } from 'ui/styles'

import { FilePickerButton } from './file-picker-button'
import { TextArea } from './textarea'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const LinkAttachBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const InputBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  right: 0;
`

const LinkAttachButton = styled(Button)`
  display: flex;
  position: absolute;
  right: 0;
  width: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${theme.palette.pink_red};

  &:hover {
    background-color: ${theme.palette.dark_pink_red};
  }
`

const FilesWrapper = styled.div<{ isWithoutTextArea: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ isWithoutTextArea }) =>
    !isWithoutTextArea &&
    css`
      margin-top: 8px;
    `}
`

const Item = styled.div`
  display: flex;
  padding: 8px 16px 0px 16px;
  align-items: center;
  color: ${theme.palette.blue_gray};
`

const Content = styled.div``

const FileTitle = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;

  overflow: hidden;
`
const ItemName = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 280px;
  margin-left: 8px;
`

const FileSize = styled(Text)`
  width: 100%;
  max-width: 80px;
  min-width: 65px;
  margin: 0px 8px;
`

const RemoveFileButton = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;

  border-radius: 8px;
  right: 0;

  &:hover {
    background-color: ${theme.palette.dark_blue};
  }
`

const ErrorText = styled(Text)`
  margin-left: 40px;
`

type Props = {
  input: InputReturn
  isValid: boolean
  placeholder?: string
  isWithoutTextArea?: boolean
  isWithoutFiles?: boolean
  isMultiple?: boolean
  isPdfFile?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FileInput: React.FC<Props> = ({
  input,
  placeholder = '',
  isWithoutTextArea = false,
  isWithoutFiles = false,
  isMultiple = false,
  isPdfFile = false,
  handleChange
}) => {
  const buttonIsDisabled: boolean = React.useMemo(() => Array.from(input.file ?? []).length >= FILE_LIMIT, [input.file])

  const getFileSize = React.useCallback((num: number) => {
    return `(${num >= 1048576 ? (num / 1024 ** 2).toFixed(1) + ' Мб' : (num / 1024).toFixed(1) + ' Кб'})`
  }, [])

  const handlerLinkAttach = React.useCallback(() => {
    if (input.secondaryValue.length >= LINK_LIMIT) {
      return
    }

    input.handlerSecondaryChange(input.value.split(/\s+/).filter(Boolean))
    input.clearField(true)
  }, [input])

  return (
    <Wrapper>
      {!isWithoutTextArea && (
        <LinkAttachBox>
          <InputBox>
            <TextArea input={input} placeholder={placeholder} enableFocusColor />
            {Boolean(input.value) && input.secondaryValue.length < LINK_LIMIT && (
              <LinkAttachButton onClick={handlerLinkAttach}>
                <Icon name="check" />
              </LinkAttachButton>
            )}
          </InputBox>
          {Boolean(input.secondaryValue) &&
            input.secondaryValue.map((link, i) => (
              <Item key={i}>
                <Icon name="link" />
                <ItemName variant="t1">{link}</ItemName>
                <RemoveFileButton onClick={() => input.handlerSecondaryChange([], i)}>
                  <Icon name="delete-file" />
                </RemoveFileButton>
              </Item>
            ))}
        </LinkAttachBox>
      )}
      {!isWithoutFiles && (
        <FilesWrapper isWithoutTextArea={isWithoutTextArea}>
          <FilePickerButton
            title="Прикрепить файл"
            onChange={handleChange}
            fileIsUploading={Boolean(input.fileIsUploading)}
            disabled={buttonIsDisabled}
            isMultiple={isMultiple}
            isPdfFile={isPdfFile}
          />
          {input.file &&
            input.file.map((file, idx) => {
              const fileSize: string = getFileSize(file.size ?? 0)
              const validColor: string = file.isValid ? theme.palette.blue_gray : theme.palette.pink_red

              return (
                <Content key={idx}>
                  <Item>
                    <Icon name="file" color={validColor} />
                    <FileTitle>
                      <ItemName variant="t1" color={validColor}>
                        {file.name}
                      </ItemName>
                      {Boolean(fileSize) && (
                        <FileSize variant="t_14_24" color={validColor}>
                          {fileSize}
                        </FileSize>
                      )}
                    </FileTitle>
                    <RemoveFileButton onClick={() => input.removeFile(idx)}>
                      <Icon name="delete-file" color={validColor} />
                    </RemoveFileButton>
                  </Item>
                  {!file.isValid && (
                    <ErrorText variant="t_14_24" color={validColor}>
                      Ошибка при загрузке файла
                    </ErrorText>
                  )}
                </Content>
              )
            })}
        </FilesWrapper>
      )}
    </Wrapper>
  )
}
