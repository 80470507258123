import React from 'react'

import { styled } from 'ui/styles'
import { ProjectSlideMobile } from '../molecules/project-slide-mobile'
import { Slide } from './slider'

const Images = styled.div`
  display: flex;
  width: calc(100% + 64px);
  height: 138px;
  margin-bottom: 12px;
  margin-left: -32px;
  padding: 0 32px;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

type Props = {
  data: Slide[]
}

export const SliderMobile: React.FC<Props> = ({ data }) => (
  <Images>
    {data.map((slide, i) => (
      <ProjectSlideMobile key={i} slide={slide} />
    ))}
  </Images>
)
