import React from 'react'
import { RootState } from 'root-reducer'
import { setActiveTag } from 'store/tag/actions'
import { connect } from 'react-redux'

import { breakpoints, useDimensions } from 'shared/dimensions'
import { TagT, VACANCY, VacancyT, VACANCY_KEYS } from 'app/data/vacancy'
import { VacancyCard } from 'app/features'
import { Page } from 'app/templates'
import { Image, NumberBulletLi, Text } from 'ui/components'
import { styled, theme, css } from 'ui/styles'

const PAGE_TEXTS = {
  title: 'Стань частью молодой амбициозной команды профессионалов',
  list: [
    'Участие в проектах федерального уровня',
    'Гибкий график и возможность удаленной работы',
    'Достойная зарплата и индивидуальный план развития',
    'Современный офис с зонами отдыха',
    'ДМС после прохождения испытательного срока'
  ],
  other: 'А еще у нас бесплатные завтраки и кофемашина =)'
}

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 56px 42px;
  overflow: auto;
`

const MainTextWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    justify-content: start;
  }
  @media (max-height: 500px) {
    justify-content: start;
  }
`

const SingleImage = styled(Image)<{ active: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -1;
  user-select: none;
  ${({ active }) =>
    active &&
    css`
      filter: blur(12px);
    `}
`

const ImageFilter = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;

  background: rgba(42, 61, 87, 0.9);
  mix-blend-mode: normal;
  z-index: -1;
`

const StyledText = styled(Text)`
  width: 100%;
  min-width: 200px;
  max-width: 450px;
`

const List = styled.ol`
  display: flex;
  counter-reset: list;
  flex-direction: column;
  margin: 32px 0;
`
const StyledNumberBulletLi = styled(NumberBulletLi)`
  justify-content: space-between;

  &::before {
    margin-right: 8px;
  }
`

const CareerContainer: React.FC<ContainerProps> = ({ activeTag, setActiveVacancy }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const activeVacancies: VacancyT[] = React.useMemo(() => {
    return (Object.keys(VACANCY) as (keyof typeof VACANCY)[]).reduce((curArr: VacancyT[], key) => {
      curArr.push(...VACANCY[key].map((el) => ({ ...el, key })))
      return curArr
    }, [])
  }, [])

  return (
    <Page>
      <Wrapper>
        <SingleImage name="career-bg.webp" width="100%" height="100%" fit="cover" active={activeTag} />
        <ImageFilter />
        {!isMobile && activeTag ? (
          activeVacancies.map((vacancy, i) => (
            <VacancyCard
              key={i}
              isDisplayed={activeTag === VACANCY_KEYS[i]}
              vacancy={vacancy}
              vacancyKey={activeTag}
              onResponseClick={() => setActiveVacancy()}
            />
          ))
        ) : (
          <ContentWrapper>
            <MainTextWrapper>
              <StyledText variant="h3" color={theme.palette.white}>
                {PAGE_TEXTS.title}
              </StyledText>
              <List>
                {PAGE_TEXTS.list.map((el, idx) => (
                  <StyledNumberBulletLi key={idx} color={theme.palette.white}>
                    <StyledText variant="t1" color={theme.palette.white}>
                      {el}
                    </StyledText>
                  </StyledNumberBulletLi>
                ))}
              </List>
              <StyledText variant="t1" color={theme.palette.white}>
                {PAGE_TEXTS.other}
              </StyledText>
            </MainTextWrapper>
          </ContentWrapper>
        )}
      </Wrapper>
    </Page>
  )
}

const mapStateToProps = (state: RootState) => ({
  activeTag: state.tag.activeTag
})

const mapDispatchToProps = (dispatch: any) => ({
  setActiveVacancy: (tag?: TagT) => dispatch(setActiveTag(tag))
})

type ContainerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export const Career = connect(mapStateToProps, mapDispatchToProps)(CareerContainer)
