import { InputReturn, InputType, useInput } from 'shared/hooks'
import { validate } from 'shared/lib/validation'

type TRow = {
  label: string
  input?: InputReturn
  placeholder?: string
  isRequired?: boolean
}

export type InputProps = Record<string, TRow>

const ROWS: InputProps = {
  lastName: {
    label: 'Фамилия*',
    placeholder: 'Константинов',
    isRequired: true
  },
  firstName: {
    label: 'Имя*',
    placeholder: 'Константин',
    isRequired: true
  },
  patronymic: {
    label: 'Отчество',
    placeholder: 'Константинович'
  },
  organization: {
    label: 'Компания',
    placeholder: 'Газпром'
  },
  email: {
    label: 'Email*',
    placeholder: 'konstantin@gazprom.ru',
    isRequired: true
  },
  phone: {
    label: 'Телефон*',
    placeholder: '+7 (900) 000-00-00',
    isRequired: true
  },
  description: {
    label: 'Опишите задание',
    placeholder: 'Требуется разработать мобильное приложение и web-кабинет для автоматизации бизнес-процессов компании.'
  },
  budget: {
    label: 'Планируемый бюджет, ₽',
    placeholder: '300 000'
  }
}

const ROW_KEYS = Object.keys(ROWS)

export type ContactUsModalReturn = ReturnType<typeof useContactUsModal>

export type ContactUsSubmitT = Record<string, string>

export const useContactUsModal = () => {
  ROWS.lastName.input = useInput({
    initialValue: ROWS.lastName.input?.value,
    inputType: InputType.Initials,
    isRequired: ROWS.lastName.isRequired
  })
  ROWS.firstName.input = useInput({
    initialValue: ROWS.firstName.input?.value,
    inputType: InputType.Initials,
    isRequired: ROWS.firstName.isRequired
  })
  ROWS.patronymic.input = useInput({
    initialValue: ROWS.patronymic.input?.value,
    inputType: InputType.Initials
  })
  ROWS.organization.input = useInput({
    initialValue: ROWS.organization.input?.value
  })
  ROWS.email.input = useInput({
    initialValue: ROWS.email.input?.value,
    isRequired: ROWS.email.isRequired,
    validate: (val) => validate(val, 'email')
  })
  ROWS.phone.input = useInput({
    initialValue: ROWS.phone.input?.value,
    isRequired: ROWS.phone.isRequired,
    inputType: InputType.Phone,
    validate: (val) => validate(val, 'phone'),
    isPhoneField: true
  })
  ROWS.description.input = useInput({
    initialValue: ROWS.description.input?.value
  })
  ROWS.budget.input = useInput({
    initialValue: ROWS.budget.input?.value,
    inputType: InputType.Number
  })

  const isInvalidLabelVisible = ROW_KEYS.some((rowKey) => !ROWS[rowKey].input?.isValid)

  const handleSubmit = (): ContactUsSubmitT => {
    let isAllValid = true

    const values = ROW_KEYS.reduce<Record<string, string>>((result, rowKey) => {
      if (!ROWS[rowKey].input?.isValid || !ROWS[rowKey].input?.onBlur()) {
        isAllValid = false
        return result
      }

      if (ROWS[rowKey].input!.value) {
        result[rowKey] = ROWS[rowKey].input!.value
      }

      return result
    }, {})

    if (!isAllValid) {
      return {}
    }

    ROW_KEYS.forEach((rowKey) => ROWS[rowKey].input?.clearField())

    return values
  }

  return { rows: ROWS, rowKeys: ROW_KEYS, isInvalidLabelVisible, handleSubmit }
}
