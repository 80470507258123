import { useDimensions } from 'shared/dimensions'
import { styled } from 'ui/styles'

const Content = styled.main`
  flex: 1 0;
  overflow: auto;
  height: 100vh;
`

export const Page: React.FC = ({ children }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  if (isMobile) {
    return null
  }

  return <Content>{children}</Content>
}
