import React from 'react'
import { styled, theme } from 'ui/styles'
import { Text } from 'ui/components'
import { TContact } from './contacts'
import { IconButton } from '../atoms'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const StyledText = styled(Text)`
  display: flex;
  min-width: 50px;
  height: 24px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 0 0 24px;
  max-width: 320px;
  width: 100%;
  /* height: 60px; */
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContactRow: React.FC<TContact> = ({ title, data, telegram, whatsApp }) => {
  const informationItems = React.useMemo(
    () => (
      <InfoWrapper>
        <TextWrapper>
          {/* {data.map((el) => ( */}
          <StyledText /** key={el} */ variant="t1" color={theme.palette.black}>
            {data[1]}
          </StyledText>
          {/* ))} */}
        </TextWrapper>
        {/* <ButtonWrapper>
          {telegram && <IconButton iconName="telegram" link={telegram} />}
          {whatsApp && <IconButton iconName="whats-app" link={whatsApp} />}
        </ButtonWrapper> */}
      </InfoWrapper>
    ),
    [data, telegram, whatsApp]
  )

  return (
    <Wrapper>
      <StyledText variant="t2" color={theme.palette.black} children={title} />
      {informationItems}
    </Wrapper>
  )
}
