import React from 'react'

import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

const StyledText = styled(Text)`
  width: 100%;
  min-width: 200px;
  max-width: 450px;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const StyledLi = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => (color ? `${color}` : theme.palette.pink_red)};
    margin-right: 16px;
    margin-top: 8px;
  }
`

type SectionProps = {
  title: string
  list: string[]
}

export const Section: React.FC<SectionProps> = ({ title, list }) => (
  <Wrapper>
    <StyledText variant="t2" color={theme.palette.white}>
      {title}
    </StyledText>
    <ListWrapper>
      {list.map((el, i) => (
        <StyledLi key={i} color={theme.palette.white}>
          <StyledText variant="t3" color={theme.palette.white}>
            {el}
          </StyledText>
        </StyledLi>
      ))}
    </ListWrapper>
  </Wrapper>
)
