import React from 'react'

import { VacancyT } from 'app/data/vacancy'
import { Section } from '.'

type SectionsProps = {
  data: VacancyT
}

export const Sections: React.FC<SectionsProps> = ({ data }) => (
  <>
    {data.description.map((el, i) => (
      <Section key={i} title={el.title} list={el.content} />
    ))}
  </>
)
