import { renderRoutes, RouteConfig } from 'react-router-config'

import { paths } from 'shared/routing'
import { Projects, Contacts, Career } from 'app'
import { NotFound } from 'app/pages'

const routes: RouteConfig[] = [
  {
    path: paths.projects,
    component: Projects,
    exact: true
  },
  {
    path: paths.contacts,
    component: Contacts,
    exact: true
  },
  {
    path: paths.career,
    component: Career,
    exact: true
  },
  {
    component: NotFound
  }
]

export const rootRoutes = () => renderRoutes(routes)
