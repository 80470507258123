import React from 'react'

import { breakpoints, useDimensions } from 'shared/dimensions'
import { Link, RedButton, Separator, Text } from 'ui/components'
import { styled } from 'ui/styles'

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
  }
`

const StyledText = styled(Text)`
  max-width: 327px;
  margin: 0 0 0 32px;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 12px 0 0 0;
    flex-direction: column;
    justify-content: center;
  }
`

type Props = {
  handlerSubmit: () => void
}

export const QuestionnaireFooter: React.FC<Props> = ({ handlerSubmit }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <>
      {!isMobile && <Separator verticalMargin={24} />}
      <Footer>
        <RedButton big onClick={handlerSubmit}>
          Отправить
        </RedButton>
        <StyledText variant="t1" align={isMobile ? 'center' : 'left'}>
          Нажимая на кнопку, вы соглашаетесь с&nbsp;
          <Link href={process.env.REACT_APP_POLICY_LINK} target="_blank">
            Обработкой персональных данных
          </Link>
        </StyledText>
      </Footer>
    </>
  )
}
