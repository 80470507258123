import React from 'react'

import { Icon, Text } from 'ui/components'
import { getStyles, styled, theme } from 'ui/styles'
import { useDimensions } from 'shared/dimensions'
import { DataFileT, InputReturn } from 'shared/hooks'

const Wrapper = styled.div<{ isMobile: boolean; isValid: boolean; isWithFile?: boolean }>`
  ${getStyles('t1')}
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 8px ${({ isWithFile }) => (isWithFile ? 42 : 16)}px 8px 16px;

  border-radius: 8px;
  border: ${({ isValid }) =>
    isValid ? `1px solid ${theme.palette.light_gray}` : `1px solid ${theme.palette.pink_red}`};
`

const FileItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  max-width: 150px;

  background-color: ${theme.palette.light_gray};
  color: ${theme.palette.blue_gray};
  border-radius: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-bottom: 1px;

  :not(:last-child) {
    margin-right: 10px;
  }
`

const FileName = styled(Text)`
  text-overflow: ellipsis;
`

const RemoveFileButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: ${theme.palette.light_gray};
  border-radius: 2px;
  right: 0;

  &:hover {
    background-color: ${theme.palette.dark_blue};
  }
`

type Props = {
  input: InputReturn
  files: DataFileT[]
  isValid: boolean
}

export const MultiFileSelect: React.FC<Props> = ({ input, files, isValid }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  return (
    <Wrapper isMobile={isMobile} isValid={isValid}>
      {Array.from(files).map((file, i) => (
        <FileItem key={i}>
          <FileName variant="t3">{file.name}</FileName>
          <RemoveFileButton onClick={() => input.removeFile(i)}>
            <Icon name="close" />
          </RemoveFileButton>
        </FileItem>
      ))}
    </Wrapper>
  )
}
