import { breakpoints } from 'shared/dimensions'
import { styled, theme } from 'ui/styles'

export const RoundBulletLi = styled.li<{ wrap?: number; color?: string }>`
  display: flex;
  align-items: start;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};

  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ color }) => (color ? `${color}` : theme.palette.pink_red)};
    margin-right: 16px;
    margin-top: 8px;
  }

  & div {
    break-inside: avoid;
    max-width: 87%;
  }

  :not(:last-child) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.mobile - 1}px) {
    & div {
      width: 85%;
    }

    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
