import React from 'react'

import { Text } from 'ui/components'
import { styled, theme } from 'ui/styles'

type Props = {
  label: string
  placeholder: string
  boldLabel: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`

export const ContainerLabel: React.FC<Props> = ({ label, placeholder, boldLabel }) => (
  <Wrapper>
    {label && <Text variant={boldLabel ? 'h5' : 't2'}>{label}</Text>}
    {placeholder && (
      <Text variant="t_14_24" color={theme.palette.dark_blue}>
        {placeholder}
      </Text>
    )}
  </Wrapper>
)
