import React from 'react'

import { styled, theme } from 'ui/styles'
import { RedButton, Text, Separator, Link } from 'ui/components'
import { breakpoints, useDimensions } from 'shared/dimensions'
import { SendRequestReturnT, useSendRequest } from 'shared/hooks'

import { Modal } from '../templates'
import { FormRow } from '../molecules/form-row'
import { ContactUsModalReturn, ContactUsSubmitT, useContactUsModal } from '../hooks'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledText = styled(Text)`
  margin: 8px 0;
`

const Footer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin-bottom: 32px;
    flex-direction: column;
  }
`

const StyledButton = styled(RedButton)`
  display: flex;
  justify-content: center;
  margin: 0 20px 0 0;

  @media (max-width: ${breakpoints.mobile - 1}px) {
    margin: 0 0 8px 0;
  }
`

type Props = {
  onClose(): void
  onSubmit(): void
}

const Header: React.FC = () => <Text variant="h3">Связаться с нами</Text>

export const ContactUsModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  const { type } = useDimensions()
  const isMobile = type === 'mobile'

  const contactUsModalData: ContactUsModalReturn = useContactUsModal()
  const request: SendRequestReturnT = useSendRequest(onSubmit, 'feedbackApplications')

  const handleSendForm = () => {
    const data: ContactUsSubmitT = contactUsModalData.handleSubmit()

    if (Object.keys(data).length) {
      request.sendRequest(data)
    }
  }

  const formItem = React.useMemo(
    () => (
      <StyledForm>
        {contactUsModalData.rowKeys.map((rowKey) => {
          const cell = contactUsModalData.rows[rowKey]
          return <FormRow key={rowKey} label={cell.label} placeholder={cell.placeholder} input={cell.input!} />
        })}
        {contactUsModalData.isInvalidLabelVisible && (
          <StyledText variant="t1" color={theme.palette.pink_red}>
            *Выделенные поля заполнены неверно.
          </StyledText>
        )}
        {request.isError && request.queryResult && (
          <StyledText variant="t1" color={theme.palette.pink_red}>
            Что-то пошло не так, пожалуйста, повторите попытку
          </StyledText>
        )}
      </StyledForm>
    ),
    [contactUsModalData, request.isError, request.queryResult]
  )

  return (
    <Modal header={<Header />} onClose={onClose} isLoading={request.isLoading}>
      {formItem}
      <Separator />
      <Footer>
        <StyledButton big onClick={handleSendForm}>
          Отправить
        </StyledButton>
        <Text variant="t1" align={isMobile ? 'center' : 'left'}>
          Нажимая на кнопку, вы соглашаетесь с&nbsp;
          <Link href={process.env.REACT_APP_POLICY_LINK} target="_blank">
            Обработкой персональных данных
          </Link>
        </Text>
      </Footer>
    </Modal>
  )
}
