import React from 'react'

export const useTextareaHeight = (ref: React.MutableRefObject<HTMLTextAreaElement | null>, value: string) => {
  React.useEffect(() => {
    if (ref && ref.current) {
      if (ref.current.style.height && !value) {
        ref.current.style.height = '0px'
      }

      ref.current.style.height = 'px'
      const scrollHeight = ref.current.scrollHeight

      ref.current.style.height = scrollHeight + 'px'
    }
  }, [ref, value])
}
