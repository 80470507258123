import { useMutation } from 'react-query'
import HttpService from 'services/main.services'

export type DataFileT = {
  name: string
  uuid: string
  size?: number
  isValid?: boolean
}

interface DataFileI {
  onFilesUpload: (result: DataFileT[]) => void
  files: FileList
}

export const useUploadFile = () => {
  const uploadFiles = async ({ onFilesUpload, files }: DataFileI) => {
    const response = await HttpService.uploadFiles(files)
    let result: DataFileT[] = []

    if (response.data) {
      result = response.data
      result.forEach((el) => {
        el.size = Array.from(files).find(
          (file) => el.name === file.name.replace(/[—()]/g, '').replaceAll(' ', '_')
        )?.size
        el.isValid = true
      })
    }

    onFilesUpload(result)
  }

  const onError = async (error: string, variables: DataFileI) => {
    const result: DataFileT[] = Array.from(variables.files).map((el) => ({
      name: el.name,
      uuid: '',
      size: el.size,
      isValid: false
    }))

    variables.onFilesUpload(result)
  }

  const { isLoading, mutate, isError } = useMutation(uploadFiles, { onError })

  return { isLoading, upload: mutate, isError }
}
