import { InputReturn, InputType, useInput } from 'shared/hooks'
import { DataFileT } from 'shared/hooks/useUploadFile'
import { validate } from 'shared/lib/validation'

type TWithFile = {
  withFile?: boolean
  isPdfFile?: boolean
  isWithoutTextArea?: boolean
  isMultiple?: boolean
  labelDescription?: string
}

type TRow = {
  label: string
  input?: InputReturn
  placeholder?: string
  isRequired?: boolean
}

const OTHER_VACANCIES: {
  vacancyId: string
  vacancyType: string
}[] = JSON.parse(process.env.REACT_APP_OTHER_VACANCIES ?? '')

type TRowsWithFile = Record<string, TRow & TWithFile>

const ROWS: TRowsWithFile = {
  lastName: {
    label: 'Фамилия*',
    placeholder: 'Константинов',
    isRequired: true
  },
  firstName: {
    label: 'Имя*',
    placeholder: 'Константин',
    isRequired: true
  },
  patronymic: {
    label: 'Отчество',
    placeholder: 'Константинович'
  },
  email: {
    label: 'Email*',
    placeholder: 'email@mail.ru',
    isRequired: true
  },
  phone: {
    label: 'Телефон*',
    placeholder: '+7 900 000-00-00',
    isRequired: true
  },
  resume: {
    label: 'Резюме*',
    placeholder: 'Файл',
    withFile: true,
    isPdfFile: true,
    isRequired: true,
    isWithoutTextArea: true
  },
  portfolioLink: {
    label: 'Портфолио',
    labelDescription: 'Файл не должен превышать 25 Мб. Можно подгрузить не более 5 файлов и 10 ссылок.',
    placeholder: 'Вставьте ссылку',
    withFile: true,
    isMultiple: true
  },
  about: {
    label: 'Пару слов о себе',
    placeholder:
      'Добрый день. Меня заинтересовала работа в вашей компании, если мои скилы и опыт вас устроят, буду рад(а) пройти собеседование.'
  }
}

const ROW_KEYS = Object.keys(ROWS)

export type VacancyApiDataT = {
  vacancy: string
  type: string
}

export type VacancyModalReturn = ReturnType<typeof useVacancyModal>

export type VacancySubmitT = Record<string, string | string[] | DataFileT[] | Record<string, string>>

export const useVacancyModal = (data: VacancyApiDataT) => {
  ROWS.lastName.input = useInput({
    initialValue: ROWS.lastName.input?.value,
    isRequired: ROWS.lastName.isRequired,
    inputType: InputType.Initials
  })
  ROWS.firstName.input = useInput({
    initialValue: ROWS.firstName.input?.value,
    isRequired: ROWS.firstName.isRequired,
    inputType: InputType.Initials
  })
  ROWS.patronymic.input = useInput({
    initialValue: ROWS.patronymic.input?.value,
    inputType: InputType.Initials
  })
  ROWS.email.input = useInput({
    initialValue: ROWS.email.input?.value,
    isRequired: ROWS.email.isRequired,
    validate: (val) => validate(val, 'email')
  })
  ROWS.phone.input = useInput({
    initialValue: ROWS.phone.input?.value,
    isRequired: ROWS.phone.isRequired,
    inputType: InputType.Phone,
    validate: (val) => validate(val, 'phone'),
    isPhoneField: true
  })
  ROWS.resume.input = useInput({
    initialValue: ROWS.resume.input?.value,
    isRequired: ROWS.resume.isRequired
  })
  ROWS.portfolioLink.input = useInput({
    initialValue: ROWS.portfolioLink.input?.value
  })
  ROWS.about.input = useInput({
    initialValue: ROWS.about.input?.value
  })

  const isInvalidLabelVisible = ROW_KEYS.some((rowKey) => !ROWS[rowKey].input?.isValid)

  const handleSubmit = (): VacancySubmitT => {
    let isAllValid = true

    const values = ROW_KEYS.reduce<VacancySubmitT>((result, rowKey) => {
      if (!ROWS[rowKey].input?.isValid || !ROWS[rowKey].input?.onBlur()) {
        isAllValid = false
        return result
      }
      if (ROWS[rowKey].input!.value) {
        result[rowKey] = ROWS[rowKey].input!.value
      }

      return result
    }, {})

    values.type = data.type.length ? data.type : OTHER_VACANCIES[0].vacancyType
    values.vacancy = data.vacancy

    if (ROWS.portfolioLink.input!.file) {
      values.portfolioFile = ROWS.portfolioLink.input!.file.map((el) => ({
        name: el.name,
        uuid: el.uuid
      }))
    }
    if (ROWS.portfolioLink.input) {
      if (ROWS.portfolioLink.input!.value || ROWS.portfolioLink.input!.secondaryValue) {
        const links = ROWS.portfolioLink.input!.value.split(/\s+/).filter(Boolean)
        ROWS.portfolioLink.input!.handlerSecondaryChange(links)
        values.portfolioLink = links.concat(ROWS.portfolioLink.input!.secondaryValue)
        ROWS.portfolioLink.input!.clearField(true)
      }
    }
    if (ROWS.resume.input!.file) {
      values.resume = ROWS.resume.input!.file.map((el) => ({
        name: el.name,
        uuid: el.uuid
      }))
    }

    if (!isAllValid) {
      return {}
    }

    ROW_KEYS.forEach((rowKey) => ROWS[rowKey].input?.clearField())

    return values
  }

  return { rows: ROWS, rowKeys: ROW_KEYS, isInvalidLabelVisible, handleSubmit }
}
