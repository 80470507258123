import React from 'react'

import { styled, theme } from 'ui/styles'
import { Image, Text } from 'ui/components'

import { Slide } from '../organisms/slider'

const Wrapper = styled.div`
  scroll-snap-align: start;
  display: flex;
  position: relative;
  height: 138px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`

const Title = styled(Text)`
  position: absolute;
  bottom: 10%;
  left: 26px;
  z-index: 1;
`

export const SingleImage = styled(Image)`
  border-radius: 24px;
  width: 291px;
  user-select: none;
`

const ImageFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(42, 61, 87, 0.5);
  transition: opacity 300ms;
  border-radius: 24px;
`

type Props = {
  slide: Slide
}

export const ProjectSlideMobile: React.FC<Props> = ({ slide }) => (
  <Wrapper onClick={slide.onClick}>
    <SingleImage name={slide.image} fit="cover" />
    <Title variant="h5" color={theme.palette.white} align="center">
      {slide.title?.toUpperCase()}
    </Title>
    <ImageFilter />
  </Wrapper>
)
