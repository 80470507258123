import { Link } from 'react-router-dom'
import { css } from 'styled-components'

import { styled } from 'ui/styles'

const styles = css<{ active: number }>`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  ${({ active, theme }) => active && `color: ${theme.palette.pink_red};`}

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -18px;
    width: 78px;
    height: 4px;

    background: ${({ active, theme }) => (active ? theme.palette.dark_pink_red : theme.palette.blue_gray)};
    transition: all ${({ theme }) => theme.transition.hover}ms linear;
    opacity: 0;

    ${({ active, theme }) => active && `color: ${theme.palette.pink_red};`}
  }

  @media (hover: hover) {
    &:hover {
      &::after {
        width: 78px;
        opacity: 1;
      }
    }
  }
`

const StyledLink = styled(Link)`
  ${styles}
`

const StyledDiv = styled.div`
  ${styles}
`

type Props = {
  isLink: boolean
  to?: string
  active: number
  forwardRef?: React.RefObject<HTMLAnchorElement | HTMLDivElement>
}

export const UnderlineTab: React.FC<Props> = ({ isLink, to = '/', forwardRef, ...props }) =>
  isLink ? (
    <StyledLink to={to} ref={forwardRef as React.RefObject<HTMLAnchorElement>} {...props} />
  ) : (
    <StyledDiv ref={forwardRef as React.RefObject<HTMLDivElement>} {...props} />
  )
